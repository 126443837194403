<template>
  <v-app
    :class="{
      mobileShowFilters: mobileShowFilters,
      mobileShowSuburbList: mobileShowSuburbList,
      mobileHideFilters: !mobileShowFilters,
      mobileHideSuburbList: !mobileShowSuburbList,

      //these are used so that small computers are classed as tablets;
      isComputer: !$vuetify.breakpoint.mdAndDown,
      isSmallComputer: !$vuetify.breakpoint.smAndDown,
      isSmallPcOrTabletOrMobile: $vuetify.breakpoint.mdAndDown,
      isTabletOrMobile: $vuetify.breakpoint.smAndDown,

      isTablet: $vuetify.breakpoint.smOnly,

      /*
          this is commented out to disable mediumComputerMode;
            isComputer: !$vuetify.breakpoint.smAndDown,
            isMediumComputer: $vuetify.breakpoint.mdOnly,
            isTabletOrMobile: $vuetify.breakpoint.smAndDown,
      */

      isfullScreenMode: fullScreenMode,

      isMobile: $vuetify.breakpoint.xsOnly,
      'my-app': true,
      isApple: isIOS
    }"
  >
    <Layout v-if="ready" />
    <WelcomeModal
      :isReady="ready"
      :showButton="false"
      v-if="true"
      class="mr-8"
    />
  </v-app>
</template>

<script>
import loadMapboxData from "./store/modules/trail/loadMapboxData.js";

import Layout from "./Layout.vue";

import { mapState } from "vuex";
import WelcomeModal from "./components/info/WelcomeModal.vue";

import "@fortawesome/fontawesome-free/css/all.css";

export default {
  name: "App",
  async mounted() {
    let mapboxTrailCampData = await loadMapboxData.loadData();

    this.$store.dispatch("trail_init_loaded_data", mapboxTrailCampData);

    //mapboxTrailCampData
  },
  components: {
    Layout,
    WelcomeModal
  },

  data: () => ({
    ready: true,
    fullScreenMode: false
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      mobileShowFilters: state => state.navigation.mobileShowFilters,
      mobileShowSuburbList: state => state.navigation.mobileShowSuburbList
    }),
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {},

  async created() {
    setInterval(() => {
      let fullScreenMode = document.fullscreenElement ? true : false;

      if (fullScreenMode !== this.fullScreenMode) {
        this.fullScreenMode = fullScreenMode;
      }
    }, 400);

    window.mvAnalyticEvent = function(category, action, label, value) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: !isNaN(value) ? value : 1
      });
      //window.gtag("event", "t_" + action + "_" + label);
    };

    document.ondblclick = function(e) {
      e.preventDefault();
    };

    document.addEventListener("gesturestart", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    window.addEventListener("orientationchange", documentHeight);
    screen.orientation.addEventListener("change", documentHeight);

    documentHeight();
  }
};
</script>
<style lang="scss">
:root {
  --doc-height: 100%;
}
body {
  //touch-action: manipulation;
  touch-action: pan-y, pan-x;
}

$body-font-family: "Lato";
$title-font: "Lato";

body div.v-application.my-app,
body div.v-application.my-app .v-card__title,
body div.v-application.my-app .v-card__subtitle {
  font-family: $body-font-family, "Lato", sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, "Lato", sans-serif !important;
  }
}

.v-application--wrap .mapboxgl-map {
  font: 12px / 20px Lato Arial, Helvetica, sans-serif;
  font: unset;

  font-family: $title-font, "Lato", sans-serif !important;
}

@media only screen and (max-width: 959px) {
  .v-btn.iconOnlyMobileButton {
    min-width: 30px !important;

    .v-icon--left {
      margin: 0 -4px;
    }

    span.label {
      display: none;
    }
  }
}
</style>
