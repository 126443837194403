<template>
  <div>
    <v-btn
      color="blue"
      elevation="2"
      icon
      outlined
      title="download section gpx file"
      @click="downloadGpx()"
    >
      <v-icon>{{ icon }}</v-icon>

      {{ title }}
    </v-btn>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { DOMImplementation } from "@xmldom/xmldom";
import GeoJsonToGpx from "@dwayneparton/geojson-to-gpx";
const implementation = new DOMImplementation();

if (implementation) {
  //do nothingl
}

export default {
  name: "geoJsonDownloadButton",
  components: {},

  props: {
    geojson: Object,
    title: String,
    icon: String,
    legTitle: String
  },
  methods: {
    downloadGpx() {
      const options = {
        metadata: {
          name: "name",
          author: {
            name: "name",
            link: {
              href: "https://www.temp.com"
            }
          }
        }
      };

      // Will convert geojson into xml document
      const gpx = GeoJsonToGpx(this.geojson, options);

      // convert document to string or post process it
      const gpxString = new XMLSerializer().serializeToString(gpx);

      // @see https://stackoverflow.com/questions/10654971/create-text-file-from-string-using-js-and-html5
      const link = document.createElement("a");
      link.download = `hh ${this.legTitle}.gpx`;
      const blob = new Blob([gpxString], { type: "text/xml" });
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  },
  computed: {}
};
</script>
