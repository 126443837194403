const turf = require("@turf/turf");

const fitlerSite = (campsitesConfig, orderIndex) => {
  let [site, ...other] = campsitesConfig.filter(row => {
    return row.orderIndex == orderIndex;
  });

  if (other) {
    //do nothing;
  }

  if (site) {
    return site;
  } else {
    return null;
  }
};

const getFromTo = (campsitesConfig, fromId, toId) => {
  let found = false;
  let ended = false;
  let output = [];
  campsitesConfig.forEach(row => {
    if (!found && row.orderIndex == fromId) {
      found = true;
    }
    if (found && !ended) {
      output.push(row);
      if (!ended && row.orderIndex == toId) {
        ended = true;
      }
    }
  });
  return output;
};

const getNewLinestring = (initalTrackLineString, fromCamp, toCamp) => {
  initalTrackLineString = JSON.parse(JSON.stringify(initalTrackLineString));
  let fromIndex = fromCamp.intersectIndex;
  let toIndex = toCamp.intersectIndex;

  try {
    initalTrackLineString.geometry.coordinates = initalTrackLineString.geometry.coordinates.filter(
      (point, index) => {
        return index >= fromIndex && index <= toIndex;
      }
    );
  } catch (error) {
    console.error("error filtering ", error);
    //do nothingk
  }
  return initalTrackLineString;
};

const _filterTrack = (
  initalTrackLineString,
  campsitesConfig,
  fromId,
  toId,
  skipCampById
) => {
  try {
    let valid = false;
    if (
      initalTrackLineString &&
      campsitesConfig &&
      !isNaN(fromId) &&
      !isNaN(toId) &&
      campsitesConfig.length
    ) {
      valid = true;
    }

    if (!valid) {
      throw { error: true, title: "validation" };
    }

    let fromSite = fitlerSite(campsitesConfig, fromId);
    let toSite = fitlerSite(campsitesConfig, toId);

    if (!fromSite || !toSite) {
      throw { error: true, title: "sitesNotFound" };
    }

    let filteredSites = getFromTo(campsitesConfig, fromId, toId);
    let filteredTrackLineString = getNewLinestring(
      initalTrackLineString,
      fromSite,
      toSite
    );

    let filteredCampsitesGeoJson = {
      type: "FeatureCollection",
      features: [
        ...filteredSites.map(row => {
          try {
            row.point.id = row?.point?.properties.id;
          } catch (error) {
            //do nothing;
          }

          return {
            ...row.point
          };
        })
      ]
    };

    let skipCampsOrderIndex = [];
    filteredCampsitesGeoJson.features.forEach(row => {
      try {
        let { properties } = row;
        let { id, orderIndex } = properties;

        if (skipCampById.length && skipCampById.includes(String(id))) {
          skipCampsOrderIndex.push(orderIndex);
        }
      } catch (error) {
        //do nothing;
      }
    });

    getLegStats(
      initalTrackLineString,
      filteredCampsitesGeoJson,
      skipCampsOrderIndex
    );

    let filteredStats = getTrackStats(
      filteredTrackLineString,
      filteredCampsitesGeoJson
    );

    let elevationData = getElevationStats(
      fromSite,
      filteredTrackLineString,
      filteredCampsitesGeoJson
    );

    let finalizeOutput = finalizeTrack(
      filteredTrackLineString,
      filteredCampsitesGeoJson
    );
    filteredTrackLineString = finalizeOutput.filteredTrackLineString;
    filteredCampsitesGeoJson = finalizeOutput.filteredCampsitesGeoJson;

    return {
      filteredCampsitesGeoJson,
      filteredSites,
      filteredTrackLineString,
      filteredStats,
      elevationData
    };
  } catch (error) {
    //do nothing;
    console.error("error", error);
  }
};

const calculateDistRiseFall = geojson => {
  let rise = 0;
  let fall = 0;
  const coordinates = geojson.geometry.coordinates;

  for (let i = 1; i < coordinates.length; i++) {
    const elevationDiff = coordinates[i][2] - coordinates[i - 1][2];
    if (elevationDiff > 0) {
      rise += elevationDiff;
    } else {
      fall -= elevationDiff;
    }
  }
  let distance = turf.length(geojson);
  return { rise, fall, distance };
};

const getLegStats = (
  initalTrackLineString,
  filteredCampsitesGeoJson,
  skipCampsOrderIndex
) => {
  let legs = [];
  let last = false;
  let first = true;
  filteredCampsitesGeoJson.features.forEach((camp, index) => {
    camp = JSON.parse(JSON.stringify(camp));
    let skip = false;
    let { intersectIndex, orderIndex, title } = camp.properties;

    let currentSiteObj = {
      intersectIndex,
      orderIndex,
      currentTripIndex: index,
      title
    };

    if (skipCampsOrderIndex.includes(orderIndex)) {
      skip = true;
    } else if (
      !first &&
      filteredCampsitesGeoJson.features.length - 1 != index &&
      skip
    ) {
      // do nothing;
    } else if (first) {
      last = currentSiteObj;
      first = false;
    } else {
      legs.push({ from: last, to: currentSiteObj });
      last = currentSiteObj;
    }
  });
  filteredCampsitesGeoJson.features.forEach(row => {
    row.properties.legLineString = null;
    row.properties.legStats = null;
  });

  legs.forEach(row => {
    let { from, to } = row;
    //let sectionLineString = getNewLinestring(filteredTrackLineString, from, to);
    let sectionLineString = getNewLinestring(initalTrackLineString, from, to);

    let { rise, fall, distance } = calculateDistRiseFall(sectionLineString);

    //row.sectionLineString = sectionLineString;

    distance = Math.round(distance * 10) / 10;
    rise = Math.round(rise * 10) / 10;
    fall = Math.round(fall * 10) / 10;

    filteredCampsitesGeoJson.features[
      from.currentTripIndex
    ].properties.legLineString = sectionLineString;

    let legTitle = `${from.title} - ${to.title}`;

    let legStats = {
      distance,
      rise,
      fall,
      legTitle
    };

    filteredCampsitesGeoJson.features[
      from.currentTripIndex
    ].properties.legStats = {
      ...legStats
    };

    //storing inital linestring without skips;
    try {
      if (
        !filteredCampsitesGeoJson.features[from.currentTripIndex].properties
          .nextCampLineString
      ) {
        filteredCampsitesGeoJson.features[
          from.currentTripIndex
        ].properties.nextCampLineString = JSON.parse(
          JSON.stringify(sectionLineString)
        );
      }
    } catch (error) {
      //do nothing;
    }
    //storing inital nextCampStats;

    try {
      if (
        !filteredCampsitesGeoJson.features[from.currentTripIndex].properties
          .nextCampStats
      ) {
        filteredCampsitesGeoJson.features[
          from.currentTripIndex
        ].properties.nextCampStats = JSON.parse(JSON.stringify(legStats));
      }
    } catch (error) {
      console.error({ error });
    }
  });

  filteredCampsitesGeoJson.features.forEach((camp, index) => {
    let { orderIndex } = camp.properties;

    if (
      orderIndex != 0 &&
      filteredCampsitesGeoJson.features.length - 1 != index &&
      skipCampsOrderIndex.includes(orderIndex)
    ) {
      camp.properties.legSkip = true;
    } else {
      camp.properties.legSkip = false;
    }
  });
};

const finalizeTrack = (filteredTrackLineString, filteredCampsitesGeoJson) => {
  let first = true;
  filteredCampsitesGeoJson.features.forEach((row, index) => {
    row.properties.pointType = "normal";
    if (row.properties.legSkip) {
      row.properties.pointType = "skipped";
    }
    if (first) {
      row.properties.pointType = "start";
    }
    if (index + 1 == filteredCampsitesGeoJson.features.length) {
      row.properties.pointType = "finish";
    }
    first = false;
  });
  return { filteredTrackLineString, filteredCampsitesGeoJson };
};

const getElevationStats = (
  fromSite,
  filteredTrackLineString,
  filteredCampsitesGeoJson
) => {
  let { intersectIndex: offSet } = fromSite;

  let lineStringDistElevationArray = [];
  let campsiteDistElevationArray = [];

  let lineString = filteredTrackLineString.geometry.coordinates;
  try {
    let lastPoint = lineString[0];
    if (lastPoint && lastPoint[2]) {
      let lastPointObj = turf.point(lastPoint);
      let runningDistance = 0;

      lineString.forEach((point, index) => {
        let elevation = point[2];
        let currentPointObj = turf.point(point);
        var distance = turf.distance(lastPointObj, currentPointObj, "meters");

        runningDistance = runningDistance + distance;

        lineStringDistElevationArray.push({
          i: index,
          e: elevation,
          d: Math.round(runningDistance * 100) / 100
          //x: point[0], prod dont need this..
          //y: point[1]
        });
        lastPoint = point;
        lastPointObj = currentPointObj;
      });
      filteredCampsitesGeoJson.features.forEach(campsite => {
        let { intersectIndex, title } = campsite.properties;
        let campSiteDetails = {
          ...lineStringDistElevationArray[intersectIndex - offSet]
        };
        campSiteDetails.title = title;
        campsiteDistElevationArray.push(campSiteDetails);
      });
    }
  } catch (error) {
    //do nothing;
  }
  return campsiteDistElevationArray.length &&
    lineStringDistElevationArray.length
    ? { campsiteDistElevationArray, lineStringDistElevationArray }
    : {};
};

const getTrackStats = (filteredTrackLineString, filteredCampsitesGeoJson) => {
  filteredCampsitesGeoJson = JSON.parse(
    JSON.stringify(filteredCampsitesGeoJson)
  );
  filteredCampsitesGeoJson.features = filteredCampsitesGeoJson.features.filter(
    camp => {
      return !camp?.properties?.legSkip;
    }
  );
  let filteredTrackLength = turf.length(filteredTrackLineString, {
    units: "kilometers"
  });
  let days = filteredCampsitesGeoJson.features.length - 1;
  let nights = filteredCampsitesGeoJson.features.length - 2;
  let average = Math.round((filteredTrackLength / days) * 10) / 10;
  filteredTrackLength = Math.round(filteredTrackLength * 10) / 10;

  let eachDaysTrip = filteredCampsitesGeoJson.features.map(row => {
    return row?.properties?.legSkip?.distance
      ? row.properties.legSkip.distance
      : row.properties.nextDist;
  });
  eachDaysTrip.length = eachDaysTrip.length - 1;

  let filteredStats = {
    distance: filteredTrackLength,
    days,
    nights,
    average,
    minDist: Math.min(...eachDaysTrip),
    maxDist: Math.max(...eachDaysTrip)
  };

  return filteredStats;
};

module.exports.filterTrack = _filterTrack;
