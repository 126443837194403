var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-card',{staticClass:"pb-1",attrs:{"elevation":"6","x_shaped":"","color":'success'}},[_c('div',{staticStyle:{"height":"0","width":"100%","overflow":"visible","text-align":"right"}},[_c('v-btn',{staticClass:"ma-1",attrs:{"icon":""},on:{"click":_vm.clearAllPopups}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"titleBox "},[(_vm.properties.Campsite)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.properties.Campsite)+" ")]):_vm._e(),(_vm.properties.Campsite)?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.properties.id)+" ")]):_vm._e()],1),_c('v-carousel',{attrs:{"height":"120px","hide-delimiters":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.colors),function(color,i){return _c('v-carousel-item',{key:color},[_c('v-sheet',{attrs:{"color":color,"height":"100%","tile":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-h2"},[_vm._v("Slide "+_vm._s(i + 1))])])],1)],1)}),1),_c('v-card-text',{},[_c('PopupDetails',{attrs:{"properties":_vm.properties}})],1),(
      (_vm.properties.pointType != 'finish' &&
        _vm.properties.legStats &&
        _vm.properties.legStats.distance &&
        _vm.properties.nextCampStats &&
        _vm.properties.nextCampStats.distance &&
        _vm.properties.nextCampStats.distance != _vm.properties.legStats.distance) ||
        !_vm.properties.legStats
    )?_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.properties.nextCampStats && _vm.properties.pointType != 'finish'),expression:"properties.nextCampStats && properties.pointType != 'finish'"}]},[(_vm.properties.nextCampStats && _vm.properties.nextCampStats.distance)?_c('font',{staticClass:"pr-1"},[_vm._v(" Dist to next camp:"+_vm._s(_vm.properties.nextCampStats.distance)+"km ")]):_vm._e()],1):_vm._e(),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.properties.legStats && _vm.properties.pointType != 'finish'),expression:"properties.legStats && properties.pointType != 'finish'"}]},[(_vm.properties.legStats && _vm.properties.legStats.distance)?_c('font',{staticClass:"pr-1"},[_vm._v(" Dist to next leg:"+_vm._s(_vm.properties.legStats.distance)+"km ")]):_vm._e(),_c('br'),(_vm.properties.legStats && _vm.properties.legStats.rise)?_c('font',{staticClass:"pr-1"},[_vm._v(" Rise:"+_vm._s(_vm.properties.legStats.rise)+"m ")]):_vm._e(),_c('br'),(_vm.properties.legStats && _vm.properties.legStats.fall)?_c('font',{staticClass:"pr-1"},[_vm._v(" Fall:"+_vm._s(_vm.properties.legStats.fall)+"m ")]):_vm._e()],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.properties.pointType != 'finish'),expression:"properties.pointType != 'finish'"}]},[_vm._v(" Distance to next: "+_vm._s(_vm.properties.nextDist)+" km (from intial config) ")]),_c('v-card-text',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.properties.Comment)+" ")]),_c('center',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-btn',{on:{"click":function($event){return _vm.nav_loadStreetView()}}},[_vm._v(" StreetView ")])],1),(_vm.properties.StreetView)?_c('v-btn',{attrs:{"href":_vm.properties.StreetView,"target":"_blank"}},[_vm._v(" StreetView ")]):_vm._e(),_c('v-card-text',{staticClass:"text--primary"},[(
        _vm.properties.pointType != 'finish' &&
          _vm.properties.pointType != 'start' &&
          _vm.functions.handleSkipCheckboxChangePopup
      )?_c('v-btn',{attrs:{"color":_vm.skipCampById.includes(_vm.properties.id) ? 'red' : 'green'},on:{"click":function($event){return _vm.functions.handleSkipCheckboxChangePopup(_vm.properties.id)}}},[_vm._v(" Skip Camp: "+_vm._s(_vm.skipCampById.includes(_vm.properties.id))+" ")]):_vm._e()],1),_c('div',{staticClass:"pa-1"},[_c('div',[_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetails),expression:"showDetails"}]},[_c('div',[_c('vue-json-pretty',{attrs:{"data":_vm.properties}})],1)])])],1)]),_c('v-card-actions',{staticClass:"pb-0 mb-0 pt-0 mt-1 mx-0 px-1"},[_c('v-btn',{staticClass:"white--text",attrs:{"small":"","depressed":"","block":"","color":"primary"},on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('span',{staticClass:"text-left"})]),_c('v-col',[_c('span',{staticClass:"text-center white--text"},[_vm._v(" "+_vm._s(!_vm.showDetails ? "show details" : "hide details")+" ")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-icon',{attrs:{"right":true}},[_vm._v(_vm._s(_vm.showDetails ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),(false)?_c('v-btn',{attrs:{"width":"100%","right":"","color":'primary'},on:{"click":_vm.clearAllPopups}},[_vm._v(" Close ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }