<template>
  <div id="elevationChartWrapper">
    <canvas id="elevationChart"></canvas>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      elevationData: state => state.trail.elevationData
    })
  },
  mounted() {
    try {
      let track = this.elevationData.lineStringDistElevationArray;
      let campSitesData = this.elevationData.campsiteDistElevationArray;

      console.log({ elevationDataA: this.elevationData });

      let campsiteIndex = campSitesData.map(row => {
        return row.i;
      });

      var elevationData = track.filter((value, index) => {
        if (
          index + 1 == track.length ||
          index === 0 ||
          index % 10 == 0 ||
          campsiteIndex.includes(index)
        ) {
          return true;
        } else {
          return false;
        }
      });

      console.log({ elevationData });

      // Extract distance and elevation values from elevationData
      var distances = elevationData.map(point => point.d);
      var elevations = elevationData.map(point => point.e);
      //var peaksDistances = campSitesData.map(point => point.d);
      //var peaksElevations = campSitesData.map(point => point.e);

      // Create a new Chart.js chart
      var ctx = document.getElementById("elevationChart").getContext("2d");
      var chart = new window.Chart(ctx, {
        type: "line",
        data: {
          labels: distances,
          datasets: [
            {
              label: "Track",
              data: elevations,
              borderColor: "grey",
              backgroundColor: "transparent",
              borderWidth: 1,
              pointStyle: false
            },
            {
              label: "Campsites",
              data: campSitesData.map(point => ({ x: point.d, y: point.e })),
              borderColor: "transparent",
              backgroundColor: "red",
              pointRadius: 5,
              pointHoverRadius: 8,
              showLine: false,
              pointStyle: "circle"
            }
          ]
        },
        options: {
          scales: {
            x: {
              type: "linear",
              position: "bottom",
              title: {
                display: true,
                text: "Distance (km)"
              }
            },
            y: {
              type: "linear",
              position: "left",
              title: {
                display: true,
                text: "Elevation (meters)"
              }
            }
          },
          plugins: {
            tooltip: {
              mode: "nearest",
              intersect: true,
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || "";
                  if (context.parsed.y !== null) {
                    label += ": " + context.parsed.y + " meters";
                  }
                  if (
                    context.dataset.label === "Peaks" &&
                    campSitesData[context.dataIndex]
                  ) {
                    label +=
                      " (" + campSitesData[context.dataIndex].title + ")";
                  }
                  return label;
                }
              }
            }
          },
          interaction: {
            intersect: false
          }
        }
      });

      // Add hover event to log information to console
      document
        .getElementById("elevationChart")
        .addEventListener("mousemove", function(event) {
          var activePoints = chart.getElementsAtEventForMode(
            event,
            "nearest",
            { intersect: true },
            true
          );

          if (activePoints.length > 0) {
            var firstPoint = activePoints[0];
            var datasetIndex = firstPoint.datasetIndex;
            var label = chart.data.labels[firstPoint.index];
            var value =
              chart.data.datasets[datasetIndex].data[firstPoint.index];
            console.log("Hovered over:", label, "e:", value);
          }
        });
    } catch (error) {
      console.error("error plotting elevation graph", { error });
    }
  }
};
</script>

<style>
#elevationChartWrapper {
  height: calc(100vh - 100px);
  overflow: hidden;
  width: 100%;
}

canvas#elevationChart {
  xwidth: 100% !important;
  xheight: calc(100vh - 100px) !important;
}
</style>
