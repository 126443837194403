<template>
  <v-card elevation="6" x_shaped v-if="ready" class="pb-1" :color="'success'">
    <div style="height:0;width:100%;overflow: visible; text-align: right;">
      <v-btn @click="clearAllPopups" class="ma-1" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="titleBox ">
      <v-card-title v-if="properties.Campsite">
        {{ properties.Campsite }}
      </v-card-title>

      <v-card-subtitle v-if="properties.Campsite">
        {{ properties.id }}
      </v-card-subtitle>
    </div>
    <v-carousel v-model="model" height="120px" hide-delimiters>
      <v-carousel-item v-for="(color, i) in colors" :key="color">
        <v-sheet :color="color" height="100%" tile>
          <v-row class="fill-height" align="center" justify="center">
            <div class="text-h2">Slide {{ i + 1 }}</div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-card-text class="">
      <PopupDetails :properties="properties"></PopupDetails>
    </v-card-text>

    <v-card-text
      v-if="
        (properties.pointType != 'finish' &&
          properties.legStats &&
          properties.legStats.distance &&
          properties.nextCampStats &&
          properties.nextCampStats.distance &&
          properties.nextCampStats.distance != properties.legStats.distance) ||
          !properties.legStats
      "
      v-show="properties.nextCampStats && properties.pointType != 'finish'"
    >
      <font
        class="pr-1"
        v-if="properties.nextCampStats && properties.nextCampStats.distance"
      >
        Dist to next camp:{{ properties.nextCampStats.distance }}km
      </font>
    </v-card-text>

    <v-card-text
      v-show="properties.legStats && properties.pointType != 'finish'"
    >
      <font
        class="pr-1"
        v-if="properties.legStats && properties.legStats.distance"
      >
        Dist to next leg:{{ properties.legStats.distance }}km
      </font>
      <br />
      <font class="pr-1" v-if="properties.legStats && properties.legStats.rise">
        Rise:{{ properties.legStats.rise }}m
      </font>
      <br />
      <font class="pr-1" v-if="properties.legStats && properties.legStats.fall">
        Fall:{{ properties.legStats.fall }}m
      </font>
    </v-card-text>

    <v-card-text class="" v-show="properties.pointType != 'finish'">
      Distance to next: {{ properties.nextDist }} km (from intial config)
    </v-card-text>

    <v-card-text class="text--primary">
      {{ properties.Comment }}
    </v-card-text>
    <center v-show="false">
      <v-btn @click="nav_loadStreetView()">
        StreetView
      </v-btn>
    </center>

    <v-btn
      v-if="properties.StreetView"
      :href="properties.StreetView"
      target="_blank"
    >
      StreetView
    </v-btn>

    <v-card-text class="text--primary">
      <v-btn
        v-if="
          properties.pointType != 'finish' &&
            properties.pointType != 'start' &&
            functions.handleSkipCheckboxChangePopup
        "
        :color="skipCampById.includes(properties.id) ? 'red' : 'green'"
        @click="functions.handleSkipCheckboxChangePopup(properties.id)"
      >
        Skip Camp:
        {{ skipCampById.includes(properties.id) }}
      </v-btn>
    </v-card-text>

    <div class="pa-1">
      <div>
        <v-expand-transition>
          <div v-show="showDetails">
            <div>
              <vue-json-pretty :data="properties" />
            </div>
          </div>
        </v-expand-transition>
      </div>
    </div>

    <v-card-actions class="pb-0 mb-0 pt-0 mt-1 mx-0 px-1">
      <v-btn
        small
        depressed
        block
        class="white--text"
        color="primary"
        @click="showDetails = !showDetails"
      >
        <v-row align="center">
          <v-col>
            <span class="text-left"></span>
          </v-col>

          <v-col>
            <span class="text-center white--text">
              {{ !showDetails ? "show details" : "hide details" }}
            </span>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon :right="true">{{
              showDetails ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-col>
        </v-row>
      </v-btn>

      <v-btn
        v-if="false"
        width="100%"
        right
        :color="'primary'"
        @click="clearAllPopups"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { eventBus } from "../../../../main";

import Vue from "vue";
import VueJsonPretty from "vue-json-pretty";
import PopupDetails from "./PopupDetails";
import "vue-json-pretty/lib/styles.css";
export default Vue.extend({
  components: { VueJsonPretty, PopupDetails },
  data: () => ({
    ready: false,
    propsToList: [""],
    showDetails: false,
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"]
  }),

  props: {
    //goToPointByPointName: Function,

    clearAllPopups: Function,
    functions: Object,
    appSettings: Object,
    maplayerdata: Object,
    skipCampById: Array,
    properties: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.$browserDetect = this.browserDetectCopy;
    try {
      this.properties.legStats = JSON.parse(this.properties.legStats);
    } catch (error) {
      //do nothing
    }

    try {
      this.properties.nextCampStats = JSON.parse(this.properties.nextCampStats);
    } catch (error) {
      //do nothing
    }

    try {
      this.properties = JSON.parse(JSON.stringify(this.properties));
    } catch (error) {
      //do nothing
    }
    try {
      delete this.properties.legLineString;
    } catch (error) {
      //do nothing
    }
    this.ready = true;
  },

  methods: {
    nav_loadStreetView() {
      eventBus.$emit("nav_loadStreetView", {
        url: this.properties.StreetView,
        title: this.properties.Campsite
      });
    }
  }
});
</script>

<style>
.moreInfoButton {
}

.mapboxgl-popup-anchor-bottom {
  margin-bottom: 5px;
}

.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galPopup {
  height: 270px;
}
.galPopup .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
  width: 285px;
  max-width: 95vw;
}

.isApple .galPopup .mapboxgl-popup-content .v-card {
  width: 315px;
}

.titleBox {
}
@media only screen and (max-width: 1263px) {
  .galPopup .mapboxgl-popup-content {
    zoom: 85%;
  }
}
@media only screen and (max-width: 650px) {
  .galPopup .mapboxgl-popup-content {
    margin-top: 12px;
    zoom: 85%;
  }

  .titleBox {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .audioBox {
    margin-bottom: -6px;
  }
}
</style>
