<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Street View - {{ currentTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>

        <iframe
          class="campSiteIframe"
          :src="currentUrl"
          :title="currentTitle"
        ></iframe>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { eventBus } from "./../../main";

export default {
  name: "streetViewDialog",

  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      currentUrl: null,
      currentTitle: null
    };
  },

  created() {
    eventBus.$on("nav_loadStreetView", ({ url, title }) => {
      this.currentUrl = null;
      this.currentUrl = null;
      this.currentUrl = url;
      this.currentTitle = title;
      if (url) {
        this.dialog = true;
      }
    });
  },
  methods: {},
  mounted() {}
};
</script>
<style>
.campSiteIframe {
  background: red;
}
</style>
