const userId = "aaronsims";
let datasets = [
  {
    name: "normal",
    trackId: "clvkf4q846xac1ntddhbz3r6u",
    campsitesId: "clvkf53dusmr81ms27nbj6a28"
  },
  {
    name: "alternative Jan 2025",
    trackId: "clvkf4q846xac1ntddhbz3r6u",
    campsitesId: "clvkvlftk73a41mtdoi3cg9ms"
  }
];

const HH_SectionSets = require("./data/default/HH_SectionSets.json");

const accessToken =
  "pk.eyJ1IjoiYWFyb25zaW1zIiwiYSI6ImNqN2Z0NjVkYjExNHkyd28yNnc5cXhjNWgifQ.cgZZrzDKR2B8wX9TBXJ_kw";

const requestData = function(datasetId, apiUrl) {
  const promise = new Promise(resolve => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        resolve({ datasetId, data });
      })
      .catch(error => {
        console.error("requestData error", { datasetId, error });
        resolve(false);
      });
  });

  return promise;
};

const _getData = async datasetId => {
  const apiUrl = `https://api.mapbox.com/datasets/v1/${userId}/${datasetId}/features?access_token=${accessToken}`;

  let result = await requestData(datasetId, apiUrl);
  return result;
};

const _loadData = async () => {
  let mapboxTrailDataObject = {};

  for (const dataset of datasets) {
    let { name, trackId, campsitesId } = dataset;

    let trackData = await _getData(trackId);
    let campsitesData = await _getData(campsitesId);

    mapboxTrailDataObject[name] = {
      name,
      trackId,
      campsitesId,
      trackData,
      campsitesData,
      sectionSet: name == "normal" ? HH_SectionSets : null
    };
  }

  return { mapboxTrailDataObject };
};

module.exports.loadData = _loadData;
