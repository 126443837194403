<template>
  <div max-width="450" class="mx-auto">
    <v-toolbar color="cyan" dark dense>
      <v-toolbar-title>List</v-toolbar-title>
    </v-toolbar>


<div class="campsiteListWrapper">

    <div v-if="filteredStats && filteredStats.distance">
      <font class="pr-1"> Distance:{{ filteredStats.distance }}km </font>

      <font class="pr-1" v-if="filteredStats">
        Days:{{ filteredStats.days }}
      </font>

      <font class="pr-1" v-if="filteredStats.nights">
        Nights:{{ filteredStats.nights }}
      </font>

      <font class="pr-1" v-if="filteredStats.average">
        avgDist:{{ filteredStats.average }}km
      </font>

      <font class="pr-1" v-if="filteredStats.minDist">
        minDist:{{ filteredStats.minDist }}km
      </font>

      <font class="pr-1" v-if="filteredStats.maxDist">
        maxDist:{{ filteredStats.maxDist }}km
      </font>
    </div>

    <br />
    <div>
      <elevationGraph
        v-if="elevationData && elevationData.campsiteDistElevationArray"
      ></elevationGraph>
    </div>

    <v-list three-line>
      <template v-for="(item, index) in filteredCampsitesArray">
        <v-list-item :key="item.id">
          <v-list-item-avatar>
            <v-icon class="grey lighten-1" dark>
              {{ index }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content
            ><a @click="openPopup(item.id)">
              <v-list-item-title
                v-html="`${item.Campsite} - ${item.id}`"
              ></v-list-item-title>
            </a>
            <v-list-item-subtitle
              v-if="item.pointType != 'finish' && !item.legSkip"
            >
              <font class="pr-1" v-if="item.legStats && item.legStats.distance">
                Dist to next camp:{{ item.legStats.distance }}km
              </font>

              <font class="pr-1" v-if="item.legStats && item.legStats.rise">
                Rise:{{ item.legStats.rise }}m
              </font>

              <font class="pr-1" v-if="item.legStats && item.legStats.fall">
                Fall:{{ item.legStats.fall }}m
              </font>
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="item.pointType != 'finish' && !item.legSkip"
            >
              <font class="pr-1" v-if="item.legStats && item.legStats.distance">
                nextDist:{{ item.nextDist }}km (fromInitalData)
              </font>
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="
                item.pointType != 'finish' &&
                  item.pointType != 'start' &&
                  item.legSkip
              "
            >
              campsite skipped
            </v-list-item-subtitle>

            <v-btn
              v-if="item.pointType != 'finish' && item.pointType != 'start'"
              :color="skipCampById.includes(item.id) ? 'red' : 'green'"
              @click="handleSkipCheckboxChange(item.id)"
            >
              Skip Camp:
              {{ skipCampById.includes(item.id) }}
            </v-btn>

            <v-list-item-subtitle v-if="item.pointType == 'finish'">
              Finish
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-btn
            class="mr-1"
            @click="openPopup(item.id)"
            color="purple"
            title="fly to point"
            elevation="2"
            icon
            outlined
          >
            <v-icon>mdi-map-marker</v-icon></v-btn
          >
          <downloadButton
            v-if="!item.legSkip && item.legStats && item.pointType != 'finish'"
            :name="item.Campsite"
            icon="mdi-download-box"
            :geojson="item.legLineString"
            :legTitle="item.legStats.legTitle"
          ></downloadButton>
        </v-list-item>
      </template>
    </v-list>


  </div>

</div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "./../../main";

import downloadButton from "./../ui/downloadButton.vue";
import elevationGraph from "./elevationGraph.vue";

export default {
  name: "TrailList",
  components: { downloadButton, elevationGraph },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      elevationData: state => state.trail.elevationData,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      filteredTrackLineString: state => state.trail.filteredTrackLineString,
      filteredStats: state => state.trail.filteredStats
    }),

    skipCampById: {
      get() {
        return this.$store.state.trail.skipCampById;
      },
      set(value) {
        console.log("set value");
        this.$store.dispatch("trail_update_skiplist_set", value);
      }
    },

    filteredCampsitesArray() {
      let array = this.filteredCampsitesGeoJson.features.map(row => {
        return row.properties;
      });

      return array;
    }
  },
  created() {},
  methods: {
    handleSkipCheckboxChange(id) {
      let isChecked = !this.skipCampById.includes(id);

      if (isChecked) {
        // Add id to the array if checked
        this.skipCampById.push(id);
      } else {
        // Remove id from the array if unchecked
        const index = this.skipCampById.indexOf(id);
        if (index > -1) {
          this.skipCampById.splice(index, 1);
        }
      }
      this.$store.dispatch("trail_update_skiplist_set", this.skipCampById);

      eventBus.$emit("map_clearAllPopups");
    },

    openPopup(id) {
      eventBus.$emit("map_openCampSitePopupById", id);
    }
  }
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}


.campsiteListWrapper{
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;



}

</style>
