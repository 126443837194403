import { render, staticRenderFns } from "./PopupDetails.vue?vue&type=template&id=2b2777f2&scoped=true&"
import script from "./PopupDetails.vue?vue&type=script&lang=js&"
export * from "./PopupDetails.vue?vue&type=script&lang=js&"
import style0 from "./PopupDetails.vue?vue&type=style&index=0&id=2b2777f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2777f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
