//import _, { initial } from "lodash";
import { mapState } from "vuex";
const turf = require("@turf/turf");

var fitBoundsToBBTimeout = null;
export default {
  data: () => ({
    //layerState: false
    currentPopup: null,
    pathsInitialized: false
  }),

  computed: {
    ...mapState({
      initalTrackLineString: state => state.trail.initalTrackLineString,
      initalCampsitesGeoJson: state => state.trail.initalCampsitesGeoJson,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      filteredTrackLineString: state => state.trail.filteredTrackLineString
    }),
    boundBoxOfTrack() {
      let bbox = null; //turf.bbox(this.filteredTrackLineString);
      try {
        bbox = turf.bbox(this.filteredTrackLineString);
      } catch (error) {
        //do nothing;
      }

      // Fit map to bounding box
      //

      return bbox;
    }
  },

  methods: {
    initializePath() {
      console.log("initializePath");

      this.map.addSource("initalTrackLineString", {
        type: "geojson",
        data: this.initalTrackLineString
      });

      this.map.addSource("initalCampsitesGeoJson", {
        type: "geojson",
        data: this.initalCampsitesGeoJson
      });

      this.map.addSource("filteredCampsitesGeoJson", {
        type: "geojson",
        data: this.filteredCampsitesGeoJson
      });

      this.map.addSource("filteredTrackLineString", {
        type: "geojson",
        data: this.filteredTrackLineString
      });

      this.map.addLayer({
        id: "initalCampsitesGeoJson",
        type: "circle",
        source: "initalCampsitesGeoJson",
        paint: {
          "circle-radius": 8,
          "circle-color": "#888"
        }
      });
      this.map.addLayer({
        id: "initalTrackLineString",
        type: "line",
        source: "initalTrackLineString",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#888",
          "line-width": 8
        }
      });
      this.map.addLayer({
        id: "filteredTrackLineString",
        type: "line",
        source: "filteredTrackLineString",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "blue",
          "line-width": 2
        }
      });

      this.map.addLayer({
        id: "filteredCampsitesGeoJson",
        type: "circle",
        source: "filteredCampsitesGeoJson",
        paint: {
          "circle-radius": 12,
          "circle-color": [
            "match",
            ["get", "pointType"],
            "start",
            "rgba(82 ,168 ,103, 0.90)", // Color for "start" points
            "skipped",
            "rgba(255, 165, 0, 0.90)", // Color for "start" points
            "finish",
            "rgba(207, 123, 123, 0.90)", // Color for "finish" points
            "normal",
            "rgba(0, 120, 255, 0.80)", // Color for "normal" points
            "gray" // Default color if "pointType" doesn't match any of the above
          ]
        }
      });

      this.pathsInitialized = true;
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    initalTrackLineString: function(newValue) {
      if (this.pathsInitialized) {
        console.log("initalTrackLineString", this.pathsInitialized, newValue);
        this.map.getSource("initalTrackLineString").setData(newValue);
      }
    },
    initalCampsitesGeoJson: function(newValue) {
      if (this.pathsInitialized) {
        console.log("initalCampsitesGeoJson", this.pathsInitialized, newValue);
        this.map.getSource("initalCampsitesGeoJson").setData(newValue);
      }
    },
    filteredCampsitesGeoJson: function(newValue) {
      if (this.pathsInitialized) {
        console.log(
          "filteredCampsitesGeoJson",
          this.pathsInitialized,
          newValue
        );
        this.map.getSource("filteredCampsitesGeoJson").setData(newValue);

        if (
          !isNaN(this.currentPopupId) &&
          newValue.features.filter(row => row.id == this.currentPopupId)
            .length == 0
        ) {
          this.clearAllPopups("Z");
        }
      }
    },
    filteredTrackLineString: function(newValue) {
      if (this.pathsInitialized) {
        console.log("filteredTrackLineString", this.pathsInitialized, newValue);
        this.map.getSource("filteredTrackLineString").setData(newValue);
      }
    },
    boundBoxOfTrack: function(bbox) {
      if (this.pathsInitialized) {
        let { padding } = this.appSettings.map.defaultSetup;

        if (padding) {
          this.map.setPadding(padding);
        }

        try {
          if (fitBoundsToBBTimeout) {
            clearTimeout(fitBoundsToBBTimeout);
          }
        } catch (error) {
          //do nothing
        }

        fitBoundsToBBTimeout = setTimeout(() => {
          this.map.fitBounds(
            [
              [bbox[0], bbox[1]],
              [bbox[2], bbox[3]]
            ],
            { padding }
          );
        }, 100);
      }
    }
  }
};
