<template>
  <div class="d-flex align-content-start flex-wrap">
    <template v-for="(item, key) in hasIcons">
      <div :key="key" class="peramItem" :title="item.title">
        <v-icon color="black" style="">
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
        :

        {{ properties[key] == 1 ? "yes" : "no" }}
      </div>
    </template>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

export default {
  name: "CampSiteOptions",
  components: {},

  data: () => ({
    hasIcons: {
      //Trackhead: { icon: "fa-solid fa-toilet", title: "Trackhead" },
      WaterTank: { icon: "fa-solid fa-bottle-water", title: "Water Tank" },
      Shelter: { icon: "fa-solid fa-people-roof", title: "Shelter" },
      Fireplace: { icon: "mdi mdi-campfire", title: "Fireplace" },
      Toilet: { icon: "fa-solid fa-toilet", title: "Toilet" },
      Camp_Fees: { icon: "fa-solid fa-sack-dollar", title: "Camp Fees" }
    }
  }),

  props: {
    properties: Object
  },

  computed: {}
};
</script>
<style scoped>
.peramItem {
  width: 100%;
}
.peramItem i {
  width: 32px;
}
</style>
