//import _, { initial } from "lodash";
import { mapState } from "vuex";
import { eventBus } from "./../../../../main";

import PopupContent from "./PopupContent.vue";
import AnimatedPopup from "mapbox-gl-animated-popup";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null,
    currentPopupId: null,
    hasInfoIdsArrayForPopup: ["filteredCampsitesGeoJson"]
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson
    }),
    skipCampById: {
      get() {
        return this.$store.state.trail.skipCampById;
      },
      set(value) {
        console.log("set value");
        this.$store.dispatch("trail_update_skiplist_set", value);
      }
    }
  },

  methods: {
    handleSkipCheckboxChangePopup(id) {
      this.clearAllPopupsQuiet();
      let isChecked = !this.skipCampById.includes(id);
      if (isChecked) {
        // Add id to the array if checked
        this.skipCampById.push(id);
      } else {
        // Remove id from the array if unchecked
        const index = this.skipCampById.indexOf(id);
        if (index > -1) {
          this.skipCampById.splice(index, 1);
        }
      }
      this.$store.dispatch("trail_update_skiplist_set", this.skipCampById);

      setTimeout(() => {
        this.openCampSitePopupFromListById(id, true);
      }, 50);
    },

    clearAllPopupsQuiet() {
      try {
        if (this.currentPopup) {
          this.currentPopup.remove();
        }
      } catch (error) {
        //do nothing;
      }
    },
    clearAllPopups(where) {
      if (where) {
        //do nothing;
      }
      try {
        if (this.currentPopup) {
          this.currentPopup.remove();
        }
      } catch (error) {
        //do nothing;
      }
      this.currentPopup = null;

      this.$store.dispatch("navUpdate_infoWindowTarget", {});
    },
    openCampSitePopupFromListById(id, quiet) {
      this.clearAllPopups("AA");

      try {
        let point = this.filteredCampsitesGeoJson.features.filter(
          row => row.id === id
        )[0];

        let { properties, geometry } = point;

        let { coordinates } = geometry;

        if (!coordinates || !properties.id) {
          throw { error: true, message: "point not found" };
        } else {
          //openPoup,
          this.openCampSitePopup(coordinates, properties);

          //flyToPoint

          if (!quiet) {
            let options = {};
            if (this.appSettings.popupMinZoom > this.currentZoom) {
              options.zoom = this.appSettings.popupTargetZoom;
            }

            this.map.flyTo({ center: coordinates, ...options });
          }
        }
      } catch (error) {
        console.error({ error });
      }
    },
    openCampSitePopup(coordinates, properties) {
      this.clearAllPopups("Z");
      this.currentPopupId = properties.id;

      this.currentPopup = new AnimatedPopup({
        className: "galPopup mvPopupAll",
        closeButton: false,

        openingAnimation: {
          duration: 300,
          easing: "easeInOutExpo", //"easeOutExpo",//"easeOutElastic",
          transform: "scale"
        },
        closingAnimation: {
          duration: 300,
          easing: "easeInBack",
          transform: "scale"
        }
      })
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        //.setOffset(this.getOffest())
        .addTo(this.map);

      try {
        let coordinatesObj = coordinates;
        if (
          coordinatesObj[0] &&
          coordinatesObj[1] &&
          !isNaN(coordinatesObj[1])
        ) {
          coordinatesObj = {
            lng: coordinatesObj[0],
            lat: coordinatesObj[1]
          };
        }

        this.$store.dispatch("navUpdate_infoWindowTarget", coordinatesObj);

        new PopupContent({
          propsData: {
            properties: properties,
            clearAllPopups: this.clearAllPopups,

            skipCampById: this.skipCampById,

            vuetifyCopy: this.$vuetify,
            browserDetectCopy: this.$browserDetect,
            appSettings: this.appSettings,
            functions: {
              handleSkipCheckboxChangePopup: this.handleSkipCheckboxChangePopup
            }
          }
        }).$mount("#vue-popup-content");
      } catch (error) {
        //do nothink;
      }
    },
    initializeInfoWindowEvents() {
      console.log("initializeInfoWindowEvents");

      this.hasInfoIdsArrayForPopup.forEach(layerId => {
        console.log("initializeInfoWindowEvents", layerId);

        if (layerId) {
          this.map.on("mouseenter", layerId, () => {
            this.map.getCanvas().style.cursor = "pointer";
          });

          // Change it back to a pointer when it leaves.
          this.map.on("mouseleave", layerId, () => {
            this.map.getCanvas().style.cursor = "";
          });

          this.map.on("click", layerId, e => {
            this.clearAllPopups("A");

            // Copy coordinates array.
            //const coordinates = e.features[0].geometry.coordinates.slice();
            const coordinates = e.lngLat;
            let [feature, otherFeatures] = e.features;
            if (otherFeatures) {
              //do notningk!!!
            }

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            let properties = feature.properties;
            this.openCampSitePopup(coordinates, properties);
          });
        }
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {
    eventBus.$on("map_openCampSitePopupById", id => {
      this.openCampSitePopupFromListById(id);
    });
    eventBus.$on("map_clearAllPopups", () => {
      this.clearAllPopupsQuiet();
    });
  },
  watch: {}
};
