const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};
const state = {
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoiYWFyb25zaW1zIiwiYSI6ImNqN2Z0NjVkYjExNHkyd28yNnc5cXhjNWgifQ.cgZZrzDKR2B8wX9TBXJ_kw",
      options: {
        style: "mapbox://styles/aaronsims/clvelu2s100y301q1dnl952sc",
        center: [147.76704578359102, -35.522942829455516],
        maxZoom: 24,
        minZoom: 1,
        pitch: 40,
        bearing: 9,
        //bearing: 0,
        zoom: 7,
        padding: { top: 70, bottom: 50, left: 50, right: 50 }
      },
      bounds: [
        [135, -45],
        [160, -25]
      ],
      defaultSetup: {
        center: [147.76704578359102, -35.522942829455516],
        zoom: 7,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 0,
        bearing: 0,
        padding: { top: 70, bottom: 50, left: 50, right: 50 }
        //bearing: 0
      },
      flyToBounds: [
        [146.41, -36.2],
        [149.141, -34.639]
      ],
      disableRotationAndBearing: false
    },

    onMoreInfoLoadOnMobileExpanText: true,

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    drawWidth: 450,
    mobDrawWidth: 300,

    popupMinZoom: 9.2,
    popupTargetZoom: 10
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
