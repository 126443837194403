//import _, { initial } from "lodash";

import { mapState } from "vuex";
import "./layerControl.css";

class LayerControls {
  constructor(options) {
    this.checkboxValues = {};

    this.container = document.createElement("div");

    this.container.className = `mapboxgl-ctrl mapboxgl-ctrl-group layerCheckboxes ${
      options.extraClass ? options.extraClass : ""
    }  ${options.persistList ? "persist-list" : ""}`;

    this.button = document.createElement("button");
    this.button.textContent = "Options";
    this.button.onclick = () => this.toggleMenu();
    this.menuWrapperWrapper = document.createElement("div");
    this.menuWrapperWrapper.className = "custom-menu-wrapper-wrapper";

    this.menuWrapper = document.createElement("div");
    this.menuWrapper.className = "custom-menu-wrapper";

    this.menu = document.createElement("div");
    this.menu.className = "custom-menu";

    this.createMenuItems(options.layers);
    this.container.appendChild(this.button);
    this.menuWrapper.appendChild(this.menu);
    this.menuWrapperWrapper.appendChild(this.menuWrapper);
    this.container.appendChild(this.menuWrapperWrapper);
  }

  updateLayers() {
    let layers = this.map.getStyle().layers;
    for (const layer of layers) {
      let { id: layerId } = layer;
      if (layerId && this.checkboxValues[layerId]) {
        if (this.checkboxValues[layerId].value === true) {
          this.map.setLayoutProperty(layerId, "visibility", "visible");
        } else {
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }
      }
    }
  }

  onAdd(map) {
    this.map = map;
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

  toggleMenu() {
    if (this.menu.style.display === "block") {
      this.menu.style.display = "none";
    } else {
      this.menu.style.display = "block";
    }
  }

  createMenuItems(options) {
    options.forEach(option => {
      let { name, id, value, terrain } = option;

      const label = document.createElement("label");
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.checked = value === true ? true : false; // You can set the default state here
      this.checkboxValues[id] = { value: checkbox.checked };
      checkbox.addEventListener("change", () =>
        this.handleSkipCheckboxChange(
          id,
          checkbox.checked,
          terrain ? true : false
        )
      );
      label.appendChild(checkbox);
      label.appendChild(document.createTextNode(name));
      this.menu.appendChild(label);
    });
    setTimeout(() => {
      this.updateLayers();
    }, 10);
  }
  handleSkipCheckboxChange(id, checked, terrain) {
    this.checkboxValues[id] = { value: checked };

    if (terrain) {
      console.log("terrain", terrain, checked);
      if (checked) {
        this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
      } else {
        this.map.setTerrain({ source: "mapbox-dem", exaggeration: 0 });
      }
    }

    this.updateLayers();
  }
}

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  watch: {},
  methods: {
    addLayerCheckboxes(target) {
      const options = {
        persistList: true,
        extraClass: "xrowLayout",

        layers: [
          {
            id: "3dterrain",
            name: "Use 3d terrain",
            terrain: true,
            value: true
          },
          {
            id: "dummydata1",
            name: "Example Layer 1",
            value: true
          },
          {
            id: "dummydata2",
            name: "Example Layer 2",
            value: true
          },
          {
            id: "dummydata3",
            name: "Example Layer 3",
            value: true
          },
          {
            id: "dummydata4",
            name: "Example Layer 4 - poi ",
            value: true
          }
        ]
      };

      const layerControls = new LayerControls(options);
      this.map.addControl(layerControls, target);
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
