<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Elevation Plot
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Elevation Plot</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <elevationGraphAct v-if="dialog"></elevationGraphAct>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import elevationGraphAct from "./elevationGraphAct.vue";

export default {
  components: { elevationGraphAct },

  data() {
    return {
      dialog: false
    };
  }
};
</script>
