<template>
  <div>
    <v-navigation-drawer
      touchless
      v-model="drawerLeft"
      app
      left
      :width="
        $vuetify.breakpoint.smAndUp
          ? appSettings.drawWidth
          : appSettings.mobDrawWidth
      "
    >
      <TrailList />
    </v-navigation-drawer>

    <v-app-bar
      dense
      flat
      app
      :color="'accent'"
      :parentdrawerLeft="drawerLeft"
      class="white--text"
      :style="{ 'border-bottom': `2px solid ${appSettings.logoTextColor}` }"
    >
      <v-app-bar-nav-icon
        @click.stop="drawerLeft = !drawerLeft"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <TrailOptions />
      <v-spacer></v-spacer>

      <v-toolbar-title class=" right mx-3">
        <div class=" headingText ">
          <span>
            {{ appTitle }}
          </span>
          <span
            class="xxfont-weight-light"
            v-if="!$vuetify.breakpoint.xsOnly"
          ></span>
        </div>
      </v-toolbar-title>

      <div class="menuBarItem">
        <v-btn
          class="reloadButton iconOnlyMobileButton"
          @click="resetEverything()"
          color="primary"
        >
          <v-icon left>mdi-reload</v-icon>

          <span class="label"></span>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid class="mapFrame" style="margin: 0;padding: 0;">
        <v-layout fill-height>
          <Map :drawer="drawerLeft" />
        </v-layout>
      </v-container>
    </v-main>

    <v-footer dense app :color="'accent'" class="white--text" v-if="false">
      footer
      <streetViewDialog />
    </v-footer>
  </div>
</template>

<script>
import { eventBus } from "./main";

import { mapState } from "vuex";
//import RulesDialog from "./components/dialogs/RulesDialog";

import Map from "./components/map/Map.vue";

//import WelcomeModal from "./components/info/WelcomeModal.vue";
import TrailOptions from "./components/controls/trailOptions.vue";
import TrailList from "./components/info/TrailList.vue";
import streetViewDialog from "./components/info/streetView.vue";

export default {
  components: { streetViewDialog, Map, TrailOptions, TrailList },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      isDevEnviroment: state => state.navigation.isDevEnviroment
    })
  },
  data: () => ({
    appTitle: "The Map",
    drawerLeft: true
  }),
  methods: {
    loadWelcome() {
      this.openIntro = false;
      eventBus.$emit("loadWelcomeDialog");
    },
    loadContact() {
      this.openIntro = false;
      eventBus.$emit("loadContactUsDialog");
    },
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },

    resetEverything() {
      eventBus.$emit("nav_map_reset");
      eventBus.$emit("nav_trail_options");
    }
  },
  created() {
    eventBus.$on("nav_layout_openMenu", () => {
      this.drawerLeft = true;
    });

    eventBus.$on("nav_layout_closeMenu", () => {
      this.drawerLeft = false;
    });
  },
  watch: {}
};
</script>

<style>
/* gets rid of the banner at the top of the page */
body {
  top: 0 !important;
}
/* get rids of the banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}

.mapFrame,
.mapWrapper,
#mapContainer {
  height: calc(100vh - 100px + 16px);

  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1263px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 110px);
    max-height: calc(var(--doc-height) - 110px);

    height: calc(100vh - 100px + 16px);
    max-height: calc(var(--doc-height) - 100px + 16px);
  }
}
@media only screen and (max-width: 959px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 90px);
    max-height: calc(var(--doc-height) - 90px);

    height: calc(100vh - 90px + 8px);
    max-height: calc(var(--doc-height) - 90px + 8px);
  }

  footer {
    font-size: 0.7em;
    height: 34px;
  }
}
.v-card__title {
  word-break: initial;
}

@media only screen and (max-width: 960px) {
  .v-footer .mr-8 {
    margin-right: 8px !important;
  }
}

.fakeEmptyMenuClass {
  height: auto;
  min-height: 10px;
  min-width: 10px;
}

.isfullScreenMode .mapboxgl-ctrl mapbox-search-box {
  display: none !important;
}

.mapboxgl-ctrl mapbox-search-box input::placeholder {
  color: rgba(255, 255, 255, 0.95);
}
@media only screen and (max-width: 1263px) {
  .mapboxgl-ctrl-top-right > .mapboxgl-ctrl {
    max-width: 200px;
  }
  mapbox-search-listbox > div > div {
    margin-left: -102px;
  }
}

@media only screen and (max-width: 960px) {
  .mapboxgl-ctrl-top-right > .mapboxgl-ctrl {
    max-width: 150px;
  }

  mapbox-search-listbox > div > div {
    margin-left: -102px;
  }
}

body
  div.v-application.my-app
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl-group
  button,
body
  div.v-application.my-app
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl-group
  button {
  width: 35px;
}
@media only screen and (max-width: 959px) {
  body div.v-application.my-app .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
    margin-right: -35px;
  }
}

body div.v-application.my-app .mapboxgl-ctrl-group.fakeEmptyMenuClass {
  border-radius: 4px;
  xbackground-color: transparent;
}

body
  div.v-application.my-app
  .mapboxgl-ctrl-group.fakeEmptyMenuClass
  button.mapElementButton {
  border-radius: 4px;
}
</style>
