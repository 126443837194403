<template>
  <div>
    <div v-if="!allSets.length" style="width:200px">
      <v-progress-linear indeterminate color="red"></v-progress-linear>
    </div>
    <div v-if="allSets.length" class="d-flex flex-row">
      <div v-if="false">
        trail version:
        <select
          style="color:black;background-color:white;border: 1px solid white;-webkit-appearance:auto;width: 90px;"
          id="selectDropdown"
          v-model="currentSet"
        >
          <option
            v-for="(set, index) in allSets"
            :value="set"
            :key="`allSets_${index}`"
            >{{ set }}</option
          >
        </select>
      </div>

      <div>
        Section Sets ({{ currentSectionIndex }}):
        <select
          style="color:black;background-color:white;border: 1px solid white;-webkit-appearance:auto;width: 90px;"
          id="selectDropdown"
          v-model="currentSectionIndex"
        >
          <option
            v-for="(set, index) in sectionSetLookup"
            :value="index"
            :key="`allSets_${index}`"
            >{{ set.name }}</option
          >
        </select>
      </div>

      <div>
        start:
        <select
          style="color:black;background-color:white;border: 1px solid white;-webkit-appearance:auto;width: 176px;"
          id="selectDropdown"
          v-model="fromCampOrderIndex"
        >
          <option
            v-for="campsite in campsitesLookup"
            :value="campsite.orderIndex"
            :key="`layer_${campsite.id}`"
            >{{ campsite.label }}</option
          >
        </select>

        <v-btn
          :disabled="!campsitesLookup[fromCampOrderIndex - 1]"
          icon
          x-small
          color="black"
          @click="fromCampOrderIndex--"
        >
          <v-icon>mdi-step-backward</v-icon>
        </v-btn>

        <v-btn icon x-small color="black" @click="fromCampOrderIndex++">
          <v-icon>mdi-step-forward</v-icon>
        </v-btn>
      </div>

      <div>
        finish:
        <select
          style="color:black;background-color:white;border: 1px solid white;-webkit-appearance:auto;width: 176px;"
          id="selectDropdown"
          v-model="toCampOrderIndex"
        >
          <option
            v-for="campsite in campsitesLookup"
            :value="campsite.orderIndex"
            :key="`layer_${campsite.id}`"
            >{{ campsite.label }}</option
          >
        </select>

        <v-btn icon x-small color="black" @click="toCampOrderIndex--">
          <v-icon>mdi-step-backward</v-icon>
        </v-btn>

        <v-btn
          :disabled="!campsitesLookup[toCampOrderIndex + 1]"
          icon
          x-small
          color="black"
          @click="toCampOrderIndex++"
        >
          <v-icon>mdi-step-forward</v-icon>
        </v-btn>
      </div>

      <div>
        <v-btn @click="orderForward = !orderForward" x-small> Direction </v-btn>
        : {{ orderForward ? "forward" : "reverse" }}
      </div>

      <div>
        <v-select
          style="width:200px"
          dense
          v-model="skipCampById"
          :items="campsitesArray"
          item-text="label"
          item-value="id"
          label="Skip Camps"
          multiple
        >
          <template v-slot:selection="{ item, index }">
            <span
              v-if="skipCampById && skipCampById.length && index === 0"
              class="black--text text-caption"
            >
              {{ skipCampById.length }} Skipped
            </span>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "TrailOptions",
  components: {},
  data() {
    return { xfrom: 1, xto: 1 };
  },
  computed: {
    ...mapState({
      sectionSetLookup: state => state.trail.sectionSetLookup,

      allSets: state => state.trail.allSets,
      campsitesConfig: state => state.trail.campsitesConfig,
      campsitesLookup: state => state.trail.campsitesLookup,

      minCampOrderIndexVal: state => state.trail.minCampOrderIndexVal,
      maxCampOrderIndexVal: state => state.trail.maxCampOrderIndexVal
    }),
    currentSectionIndex: {
      get() {
        return this.$store.state.trail.currentSectionIndex;
      },
      set(value) {
        this.$store.dispatch("trail_update_current_section_index", value);
      }
    },

    fromCampOrderIndex: {
      get() {
        return this.$store.state.trail.fromCampOrderIndex;
      },
      set(value) {
        if (value === false || !this.campsitesLookup[value]) {
          value = this.minCampOrderIndexVal;
        }

        this.$store.dispatch("trail_update_from", value);
      }
    },
    currentSet: {
      get() {
        return this.$store.state.trail.currentSet;
      },
      set(value) {
        this.$store.dispatch("trail_update_current_set", value);
      }
    },
    toCampOrderIndex: {
      get() {
        return this.$store.state.trail.toCampOrderIndex;
      },
      set(value) {
        if (value === false || !this.campsitesLookup[value]) {
          value = this.maxCampOrderIndexVal;
        }
        this.$store.dispatch("trail_update_to", value);
      }
    },
    orderForward: {
      get() {
        return this.$store.state.trail.orderForward;
      },
      set(value) {
        this.$store.dispatch("trail_setOrder", value);
      }
    },
    skipCampById: {
      get() {
        return this.$store.state.trail.skipCampById;
      },
      set(value) {
        this.$store.dispatch("trail_update_skiplist_set", value);
        eventBus.$emit("map_clearAllPopups");
      }
    },

    campsitesArray() {
      let array = Object.values(this.campsitesLookup);

      array = array.map(row => {
        let { title, id, label } = row;
        return { title, id, label };
      });
      return array;
    }
  },
  created() {
    eventBus.$on("nav_trail_options", () => {
      this.$store.dispatch("trail_reset_options");
    });
  },
  methods: {}
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}
</style>
