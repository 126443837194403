//import _, { initial } from "lodash";

import { mapState } from "vuex";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata
    })
  },

  methods: {
    addControlsAndAddressSearch() {
      var theIntervId = setInterval(
        () => {
          //https://docs.mapbox.com/api/search/search-box/
          if (window.MapboxSearchBox && window.MapboxSearchBox != "notReady") {
            const searchBox = new window.MapboxSearchBox();
            searchBox.accessToken = this.appSettings.map.accessToken;

            let bbox = this.appSettings.map.bounds;

            searchBox.options = {
              types: "address, poi, district, place, locality, neighborhood",
              proximity: [144.946457, -37.840935],
              bbox
            };
            searchBox.marker = false;

            searchBox.mapboxgl = window.mapboxgl;
            this.map.addControl(searchBox);

            // Add navigation control to the top-right corner
            this.map.addControl(
              new window.mapboxgl.NavigationControl(),
              "top-left"
            );

            // Add fullscreen control to the top-right corner
            this.map.addControl(
              new window.mapboxgl.FullscreenControl(),
              "top-left"
            );

            this.addLayerCheckboxes("top-right");

            //this.map.addControl(new window.mapboxgl.AttributionControl(), 'bottom-left');

            clearInterval(theIntervId);
          }
        },

        10
      );
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
